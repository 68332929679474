import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CcNavbarModule } from '@hs/ui-core-navbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { IpWhitelistingEffects } from 'app/admin/store/ip-whitelisting/ip-whitelisting.effects';
import { ipWhitelistingKey, ipWhitelistingReducer } from 'app/admin/store/ip-whitelisting/ip-whitelisting.reducer';
import { AppComponent } from 'app/app.component';
import { routes } from 'app/app.routes';
import { AuthEffects } from 'app/auth/store/auth/auth.effects';
import { authKey, authReducer } from 'app/auth/store/auth/auth.reducer';
import { TwoFactorEffects } from 'app/auth/store/auth/two-factor.effects';
import { authMethodKey, authMethodReducer } from 'app/auth/store/auth-method/auth-method.reducer';
import { failureKey, failureReducer } from 'app/auth/store/failure/failure.reducer';
import { MaintenanceEffects } from 'app/auth/store/maintenance/maintenance.effects';
import { maintenanceKey, maintenanceReducer } from 'app/auth/store/maintenance/maintenance.reducer';
import { messageKey, messageReducer } from 'app/auth/store/message/message.reducer';
import { RedirectEffects } from 'app/auth/store/redirect/redirect.effects';
import { redirectKey, redirectReducer } from 'app/auth/store/redirect/redirect.reducer';
import { SessionEffects } from 'app/auth/store/session/session.effects';
import { sessionKey, sessionReducer } from 'app/auth/store/session/session.reducer';
import { HedgeServHeadersInterceptor } from 'app/shared/http-interceptors/hedgeserv-headers.interceptor';
import { ErrorHandlerService } from 'app/shared/services/logging/error-handler.service';
import { Logger } from 'app/shared/services/logging/logger';
import { LoggerService } from 'app/shared/services/logging/logger.service';
import { environment } from 'environments/environment';
import { EnvironmentService } from 'environments/environment.service';

if (!environment.dev) {
    enableProdMode();
}

bootstrapApplication(
    AppComponent,
    {
        providers: [
            provideRouter(routes),
            provideHttpClient(withInterceptorsFromDi()),
            EnvironmentService,
            { provide: ErrorHandler, useClass: ErrorHandlerService },
            { provide: Logger, useClass: LoggerService },
            { provide: HTTP_INTERCEPTORS, useClass: HedgeServHeadersInterceptor, multi: true },

            importProvidersFrom(
                StoreModule.forRoot({
                    [ipWhitelistingKey]: ipWhitelistingReducer,
                    [authMethodKey]: authMethodReducer,
                    [failureKey]: failureReducer,
                    [messageKey]: messageReducer,
                    [redirectKey]: redirectReducer,
                    [sessionKey]: sessionReducer,
                    [authKey]: authReducer,
                    [maintenanceKey]: maintenanceReducer,
                }),
                EffectsModule.forRoot([
                    IpWhitelistingEffects,
                    AuthEffects,
                    RedirectEffects,
                    SessionEffects,
                    TwoFactorEffects,
                    MaintenanceEffects,
                ]),
                StoreDevtoolsModule.instrument({ maxAge: 100 }),
            ),

            // we have to use the old module because Core Components does not provide the necessary animation metadata
            // provideAnimationsAsync(),
            importProvidersFrom(BrowserAnimationsModule),

            importProvidersFrom(CcNavbarModule), // we need this due to an issue in core components
        ],
    },
)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
